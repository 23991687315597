
import Image, { GatsbyImageFluidProps } from 'gatsby-image'
import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import BackgroundImage from 'gatsby-background-image'
import { GraphQLQuery } from '../types'
import ArticleHeader from '../components/articleHeader'

type Props = {
  markdownRemark: {
    frontmatter: {
      title: string
      author: string
      date: string
      description: string
      slug: string
      section: string
      caption: string
      featuredImage: {
        childImageSharp: GatsbyImageFluidProps
      } | string
    }
    html: string
  }
}

const ArticleTemplate = ({ data }: GraphQLQuery<Props>) => {
  return (
    <Layout postTitle={data.markdownRemark.frontmatter.title}
    postImage={(data.markdownRemark.frontmatter.featuredImage as { childImageSharp: GatsbyImageFluidProps}).childImageSharp?.fluid || data.markdownRemark.frontmatter.featuredImage as string}
    postDescription={data.markdownRemark.frontmatter.description}
    isHome={false}
    slug={data.markdownRemark.frontmatter.slug}
    >
    <div className="bg-gray-50 text-gray-600">
      <ArticleHeader backgroundImage={(data.markdownRemark.frontmatter.featuredImage as { childImageSharp: GatsbyImageFluidProps}).childImageSharp || data.markdownRemark.frontmatter.featuredImage as string} title={data.markdownRemark.frontmatter.title} description={data.markdownRemark.frontmatter.description} author={data.markdownRemark.frontmatter.author} />
        <p>{data.markdownRemark.frontmatter.caption}</p>
      <div className="max-w-4xl mx-auto pb-12">
        <div className="px-4 md:px-0">
          <div className="text-md md:text-lg leading-8 font-normal px-4 md:px-8 space-y-4" dangerouslySetInnerHTML={{__html: data.markdownRemark.html}}>
          </div>
        </div>
      </div>
    </div>
    </Layout>
  )
}

export default ArticleTemplate

export const query = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: {slug: {eq: $slug}}) {
      frontmatter {
        title
        author
        date
        description
        slug
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      html
    }
  }
`