import BackgroundImage from 'gatsby-background-image'
import { GatsbyImageFluidProps } from 'gatsby-image'
import React from 'react'

type Props = {
    backgroundImage: GatsbyImageFluidProps | string
    title: string
    description?: string
    author: string
}

const ArticleHeader: React.FC<Props> = ({ backgroundImage, title, description, author }) => {
    return (
        <BackgroundImage
        Tag='div'
        className='h-screen w-full z-20'
        fluid={(backgroundImage as GatsbyImageFluidProps).fluid}
      >
      <div className="w-screen m-0 mr-0 h-full flex flex-col justify-end items-center">
        <div className="p-8 pb-0 flex max-w-4xl flex-col bg-white items-center rounded-t-md">
          <h1 className="text-gray-800 font-bold text-xl md:text-4xl">
            {title}
          </h1>
          <p className="italic pt-6 text-gray-500 text-sm md:text-base">
            {description}
          </p>
          <p className="font-semibold text-gray-700 pt-6 text-sm md:text-base">
            {author}
          </p>
        </div>
        </div>
      </BackgroundImage>
    )
}

export default ArticleHeader